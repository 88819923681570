export const MENU_SETUP = [
	'655bdefec638612b5cb93622',
	// '663d840b6084a5526c73090d',
	'663d83ef6084a5526c730211',
	'663b860e0e3645ec1c61b2ba',
	'663d83d36084a5526c72f7ee',
	// '663d83af6084a5526c72eafd',
	// '663b860e0e3645ec1c61b2ba',
	'655be0760b9d263d9013c7fa',
	'653f9e487fbddd28592f642c',
	'663d840b6084a5526c73090d',
];
