// import { format } from 'date-fns';
import React from 'react';

import { Order } from '@monorepo/upsell-kit';

import { CartIcon } from '../../../_shared/Icons';

const OrderHeader = ({ order, isPriceHide }: { order: Order; isPriceHide: boolean }) => {
	return (
		<div className="w-full">
			<div className="flex w-full flex-grow flex-row gap-4">
				<div className="flex-cc flex h-12 w-12 rounded-full bg-[#F0F0F0] p-2.5">
					<CartIcon size={24} color="#414A53" />
				</div>
				<div className="flex-ss flex w-full flex-col gap-2 ">
					<div className="flex-bc flex w-full">
						<h3 className=" text-base font-bold text-black">
							{order.branch.location_name}
						</h3>
						{!isPriceHide && <p className="text-sm font-medium">{order.total} Kr</p>}
					</div>
					<span className="flex-ss flex w-full flex-row text-xs sm:text-sm">
						<span>{`${order.items?.length} ${order.items?.length > 1 ? 'vörur' : 'vara'}`}</span>
						{/* <div className=" mx-1 h-1 w-1 rounded-md bg-gray-200"></div>
						<span>{format(new Date(`${order.createdAt}`), 'd MMM yyyy, HH:mm')}</span> */}
					</span>
				</div>
			</div>
		</div>
	);
};

export default OrderHeader;
