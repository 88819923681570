'use client';

import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import upsell, { Order, cartStorage } from '@monorepo/upsell-kit';

import Animate from '../../components/Animation/Animate';
import Button from '../../components/_shared/Button';
// import OrderSummary from '../../components/profile/MyOrders/ActiveOrdersCard/OrderSummary';
import OrderHeader from '../../components/profile/MyOrders/HistoryOrdersCard/OrderHeader';

/**
 * Props for `OrderHistory`.
 */
//
export type OrderHistoryProps = SliceComponentProps<Content.OrderHistorySlice>;

/**
 * Component for "OrderHistory" Slices.
 */
const OrderHistory = ({ slice }: OrderHistoryProps): JSX.Element => {
	const router = useRouter();
	const { data: historyOrders, isLoading: historyLoading } = upsell.data.useOrdersHistory({
		limit: '3',
	});

	const isOrdersAvailable = historyOrders && historyOrders.length > 0;

	const { changeBranch } = upsell.provider.checkout.useContext();

	const handleReorder = (orderData: Order, id: string) => {
		if (orderData) {
			const currentCart = cartStorage.get();
			localStorage.setItem('@subway-web/saved-cart', JSON.stringify(currentCart));
			cartStorage.set(orderData.items_as_cart);
			router.push('/checkout?reorder=' + id);
			changeBranch('');
		}
	};

	return (
		<div className="flex-cc flex ">
			<div className="flex-cc container flex w-full">
				{isOrdersAvailable && (
					<>
						{historyLoading ? (
							<div>Loading</div>
						) : (
							<div className=" flex w-full max-w-[1280px] flex-col items-center gap-7 px-0 py-10 md:px-0 md:pb-20 md:pt-16">
								<Animate className=" flex-bc flex w-full justify-between  text-base ">
									<div className=" animate right text-lg font-bold text-black md:text-[32px]">
										{slice.primary.title}
									</div>

									<Link href={`${slice.primary.cta_link}`}>
										<Button
											className="animate left !w-fit !px-6 !py-2.5 !font-medium"
											theme="default"
										>{`${slice.primary.cta_text}`}</Button>
									</Link>
								</Animate>

								<div className=" flex w-full">
									<Animate
										type="stack"
										className=" grid   w-full grid-cols-1  gap-4 md:!w-full md:grid-cols-3 md:gap-4"
									>
										{historyOrders.map((order, i) => {
											const firstGroup = order.items.slice(0, 4);
											const secondGroup =
												order.items.length > 4 ? order.items.slice(4) : [];

											return (
												<div className={`animate up h-full `}>
													<div
														key={i}
														className={`  m-0 flex h-full !w-full flex-col rounded-xl border border-gray-200 bg-white md:!w-full   `}
													>
														<div className="flex w-full  px-3.5 pt-4 lg:px-6 lg:pt-5">
															<OrderHeader
																order={order}
																isPriceHide={false}
															/>
														</div>

														<div className="flex w-full flex-col">
															<div className="flex flex-row px-3.5 pb-5 pt-4">
																<div className="grid grid-cols-5 gap-2">
																	{firstGroup.map((item) => {
																		return (
																			<div className="flex-cc relative  flex rounded-md border border-primary border-opacity-20 p-1.5">
																				<div className="relative h-[44px] w-[44px] md:h-[50px] md:w-[50px]">
																					{'image' in
																						item &&
																						item.image && (
																							<Image
																								fill
																								className=" object-contain"
																								alt=""
																								src={
																									item.image
																								}
																							/>
																						)}
																				</div>
																			</div>
																		);
																	})}

																	{secondGroup.length > 0 && (
																		<div
																			className={`flex-cc  relative flex   rounded-md border border-primary border-opacity-20 p-1.5  ${secondGroup.length > 1 ? '!grid grid-cols-2 gap-[3px]' : ''}`}
																		>
																			{secondGroup.map(
																				(item, key) =>
																					'image' in
																						item &&
																					item.image && (
																						<>
																							{key <
																								4 && (
																								<div
																									className={`flex-cc relative flex ${secondGroup.length > 1 ? 'h-[20px] w-[20px] md:h-[22px] md:w-[22px]' : 'h-[44px] w-[44px] md:h-[50px] md:w-[50px]'}`}
																								>
																									{key >
																										2 &&
																									secondGroup.length >
																										4 ? (
																										<div
																											className={`flex-cc  h-full w-full rounded-md bg-gray-100 text-xs text-theme-green-dark `}
																										>
																											{secondGroup.length -
																												4}

																											+
																										</div>
																									) : (
																										<Image
																											key={
																												key
																											} // Added key prop for each image
																											fill
																											className=" object-contain"
																											alt=""
																											src={
																												item.image
																											}
																										/>
																									)}
																								</div>
																							)}
																						</>
																					),
																			)}
																		</div>
																	)}
																</div>
															</div>

															<div className="grid w-full grid-cols-2 flex-row border-t">
																<Link
																	className="flex-cc flex w-full cursor-pointer border-r hover:bg-gray-50"
																	href={`/profile/order/${order._id}`}
																>
																	<p className="text-sm font-medium uppercase">
																		Nánar
																	</p>
																</Link>
																<div
																	className="flex-cc flex w-full cursor-pointer hover:bg-gray-50"
																	onClick={() => {
																		handleReorder(
																			order,
																			order._id,
																		);
																	}}
																>
																	<p className="py-3 text-sm font-medium uppercase text-theme-green">
																		Panta aftur
																	</p>
																</div>
															</div>
														</div>

														{/* <div className="flex w-full flex-col px-3.5 py-4 lg:px-6 lg:py-5">
															<OrderSummary
																items={order.items}
																type="slice"
															/>
														</div> */}
													</div>
												</div>
											);
										})}
									</Animate>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default OrderHistory;
